<template>
  <div class="component-all">
    <headerComponent>
    </headerComponent>

    <div class="container headline" ref="headline">
      <div class="anos-experiencia container1">
        <div class="anosdexperiencia-content">
          <h2>{{ $t('salutation.sobre_home_title') }}</h2>
        </div>
        <div class="anosdexperiencia-copy">
          <p>{{ $t('salutation.sobre_home_desc') }}</p>
          <p>{{ $t('salutation.empresa') }}</p>
        </div>
        <div class="anosdexperiencia-copy1">
          <p>{{ $t('salutation.com_anos') }}</p>
        </div>
      </div>
      <hr>
      <!-- Animação anos de experiencia -->
    </div>

    <div class=" oquefazemos">
      <div class="fazemos-title">
        <h2>{{ $t('salutation.fazemos_title') }}</h2>
        <div class="icon-logo">
          <img src="/img/fazemos.svg" alt="">
        </div>
      </div>
      <div class="oquefazemoscopy">
        <p>{{ $t('salutation.fazemos_desc') }}</p>
      </div>

      <div class="projetos-fazemos">
        <!-- Card 1 -->
        <div class="projeto projeto1">
          <div class="desc-fazemos">
            <div class="titlo-desc">
              <span>{{ $t('salutation.fazemos_pavimento_title') }}</span>
            </div>
            <p>{{ $t('salutation.fazemos_pavimento_desc') }}</p>
          </div>
          <div class="projetoImage-fazemos">
            <div class="imagemFazemos">
              <img src="/img/servico3.png" class="imagem-line" alt="">
            </div>
          </div>
        </div>
        <!-- Fim Card 1 -->

        <!-- Card 2 -->
        <div class="projeto projeto2">
          <div class="desc-fazemos">
            <div class="titlo-desc">
              <span>{{ $t('salutation.fazemos_sanitario_title') }}</span>
            </div>
            <p>{{ $t('salutation.fazemos_sanitario_desc') }}</p>
          </div>
          <div class="projetoImage-fazemos">
            <div class="imagemFazemos">
              <img src="/img/servico5.png" class="imagem-line" alt="">
            </div>
          </div>
        </div>
        <!-- Fim Card 2 -->

        <!-- Card 3 -->
        <div class="projeto projeto3">
          <div class="desc-fazemos">
            <div class="titlo-desc">
              <span>{{ $t('salutation.fazemos_cozinha_title') }}</span>
            </div>
            <p>{{ $t('salutation.fazemos_cozinha_desc') }}</p>
          </div>
          <div class="projetoImage-fazemos">
            <div class="imagemFazemos">
              <img src="/img/servico6.png" class="imagem-line" alt="">
            </div>
          </div>
        </div>
        <!-- Fim Card 3 -->

      </div>
    </div>
    <!-- Fim da Div o que fazemos -->

    <!-- Nossos Trabalhos -->
    <NossosTrabalhosComponent/>
    <TrabalhosComponent/>
  </div>
</template>

<script>
import headerComponent from '../components/Header/headerComponent.vue'
import TrabalhosComponent from '../components/Trabalhos/TrabalhosComponent.vue'
import NossosTrabalhosComponent from '../components/Trabalhos/NossosTrabalhosComponent.vue'
import ScrollReveal from 'scrollreveal';

export default {
  name: 'HomeView',
  components: {
    headerComponent,
    TrabalhosComponent,
    NossosTrabalhosComponent
  },
  mounted() {
    // Inicializa o ScrollReveal no mounted
    this.initScrollReveal();
  },
  methods: {
    initScrollReveal() {
    this.sr = ScrollReveal();

    this.sr.reveal('.trabalhos', {
      duration: 2000,
      distance: '30px',
      origin: 'left', // Aparecerá da direita
      viewFactor: 0.5,
      //reset: true
    });
  },
  setLocale(locale) {
      this.$i18n.locale = locale;
    }, 
  },
  beforeUnmount() {
    // Destrua o ScrollReveal ao sair do componente
    if (this.sr) {
      this.sr.destroy();
    }
  }
}
</script>

<style scoped>
.component-all{
  margin-bottom: 10%;
}
.anos-experiencia {
  margin-top: 10%;
  width: 100%;
  flex-wrap: wrap;
}

.anos-experiencia h2 {
  font-size: 1.5rem;
}
.container{
  width: 70%;
}
.container hr {
  padding: 1px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border: none;
  background-color: #ACACAC;
}

/* Anos de experiencia */
.anos-experiencia {
  display: flex;
  text-align: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.anosdexperiencia-content {
  width: 40%;

}

.anosdexperiencia-content h2 {
  font-size: 30px;
}

.anosdexperiencia-content h2,
p {
  margin-right: 30%;
}

.anosdexperiencia-copy,
.anosdexperiencia-copy1 {
  font-size: 14px;
  width: 30%;
  color: #000000;
}

/* O que fazemos */
.oquefazemos {
  width: 70%;
  text-align: start;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;


}

.projetoImage-fazemos {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  width: 40%;
  margin-top: -2%;
  margin-left: auto;

}
.projeto2 .projetoImage-fazemos{
  margin-top: -8%;
}
.projetoImage-fazemos img{
  width: 40%;
}
.projeto2 {
  background-color: black;
  color: white;
  margin-top: 5%;
}

.projeto1 span, .projeto3 span{
  color: #000000;
}
.projeto2 span{
  color: #fff;
}

.desc-fazemos{
  padding-bottom: 15%;
}
.desc-fazemos p {
  display: flex;
  width: 76%;
}
.desc-fazemos .titlo-desc{
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
}

.imagemFazemos {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.imagem-line {
  width: 50%;
  top: -10px;
  position: relative;
}


.imagem-teste {
  height: 15%;
  padding-top: 20%;
  width: 60%;
}

.imagem-teste img {
  top: -20px;
  width: 80%;
}

.fazemos-title {
  display: flex;
align-items: center;
}
.icon-logo{
  width: 3%;
  padding-left: 1%;
}

.oquefazemos h2 {
  font-size: 40px;
}

.projetos-fazemos {
  width: 100%;
  display: flex;
  justify-content: space-around;

}

.projeto {
  width: 70%;
  height: 40vh;

}
.projeto1, .projeto3{
  border: 2px solid #ACACAC;
  color: #000000 !important;

}
.projeto span {
  font-weight: 700;  
}

.projeto2 p, .projeto p {
  font-size: 14px;
  display: flex;
  padding-left: 5%;
}
.oquefazemoscopy{
line-height: 30px;
font-size: 14px;
color: #000000;
margin-bottom: 8%;
width: 60%;
}

@media screen and (max-width: 768px) {
  .container{
    width: 100%;
  }
  .anos-experiencia{
    width: 100%;
    padding: 10px;
  }
  .anos-experiencia h2{
    font-size: 25px;
    margin-right: 0%;
    padding-bottom: 20px;
    font-weight: bold;
    color: #000000;

  }
.anosdexperiencia-content{
  flex-wrap: wrap;
  width: 100%;
}
.anosdexperiencia-copy, .anosdexperiencia-copy1{
  width: 100%;
}
.anosdexperiencia-copy p,.anosdexperiencia-copy1 p{
  width: 100%;
  padding-right: 10px;
}
.fazemos-title h2{
  font-size: 28px;
  font-weight: bold;
  text-align: start;
  color: #000000;
}
.container hr {
width: 90%;
}
.icon-logo {
  width: 14%;
}
.icon-logo img{
  width: 100%;
}
.oquefazemoscopy{
  width: 100%;
}
.oquefazemoscopy p{
  width: 100%;
  line-height: 20px;
}
.oquefazemos{
  width: 80%;
}
.projetos-fazemos{
width: 100%;
flex-wrap: wrap;
}
.projeto1{
  margin-bottom: 10px;

}
.projeto{
  width: 100%;
  margin-bottom: 10px;
}

}
/* Estilos para dispositivos com largura de tela até 320 pixels (iPhone 5 em modo retrato) */
@media only screen and (max-width: 320px) {
  .projeto {
  height: 60vh;

}
}

/* Tela P.c Pequeno */
@media screen and (max-width: 1024px) and (max-height: 600px) {
  .projeto{
    height: 50vh;
  }
}


/*Iped Ir 4 */

@media screen and (min-width:820px) and (max-width: 1180px){
  .anosdexperiencia-content h2 {
  font-size: 24px;
}
.projetos-fazemos{
  flex-wrap: wrap;
  justify-content: center;
}
.projeto{
  height: 55%;
}
.oquefazemoscopy{
  width: 100%;
}
}

/*  Galax Tab S7 */
@media screen and (min-width:800px) and (max-width: 1280px){ 
  .anosdexperiencia-content h2 {
  font-size: 24px;
} 
  .projetos-fazemos{
  flex-wrap: wrap;
  justify-content: center;
}
}

@media screen and (min-width:769px) and (max-width: 800px){ 
  .anosdexperiencia-content h2 {
  font-size: 24px;
}
}

</style>


