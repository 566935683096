<template>
    <div class="principal">
        <nav class="navbar navbar-expand-lg bg-body-tertiary" style="z-index: 9999;">
            <div class="container-fluid">
                <div class="navbar-brand logo-img">
                    <router-link to="/">  
                        <img src="/img/logo.svg" alt="Aqui tem o logotipo da Pavisani" srcset="" width="180">
                    </router-link>
                </div>
    
    <button class="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNav" 
            aria-controls="navbarNav" 
            aria-expanded="false" 
            aria-label="Toggle navigation" 
            @click="toggleMenu">
        <span class="navbar-toggler-icon hamburger-icon" v-if="!menuOpen"></span>
        <span class="btn-close bi bi-x" v-if="menuOpen"></span>
    </button>
    
                <div class="collapse navbar-collapse" id="navbarNav">
                    <div class="content menu">
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <router-link @click="closeMenu" to="/">  
                                    <a class="nav-link active" aria-current="page" href="#">{{ $t('salutation.home') }}</a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link @click="closeMenu" to="/about"> 
                                    <a class="nav-link" href="#">{{ $t('salutation.sobre') }}</a>
                                </router-link> 
                            </li>
                            <li class="nav-item">
                                <router-link @click="closeMenu" to="/parceiros">   
                                    <a class="nav-link" href="#">{{ $t('salutation.parceiros') }}</a>
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link @click="closeMenu" to="/servico">   
                                    <a class="nav-link" href="">{{ $t('salutation.servicos') }}</a>
                                </router-link>
                            </li>
                         <!--   <li class="nav-item">
                                <router-link @click="closeMenu" to="/blog">  
                                    <a class="nav-link" href="">{{ $t('salutation.blog') }}</a>
                                </router-link>
                            </li>
                         
                            <li class="nav-item">
                                <router-link @click="closeMenu" to="/galeria">  
                                    <a class="nav-link" href="">{{ $t('salutation.galeria') }}</a>
                                </router-link>
                            </li>
                               -->
                            <li class="nav-item">
                                <router-link @click="closeMenu" to="/contacto">
                                    <a class="nav-link" href="">{{ $t('salutation.contacto') }}</a>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="form-check form-switch">
        <label v-if="$i18n.locale === 'pt_BR'" for="languageSwitchPT" :style="{ 'margin-right': $i18n.locale === 'pt_BR' ? '10px' : '0' }">PT</label>
        <label v-else for="languageSwitchEN" class="form-check-label" :class="{ active: $i18n.locale === 'pt_BR' }" @click="toggleLanguage, mudarRota(),mudarRota1()">EN</label>
    
        <input class="form-check-input" type="checkbox" role="switch" :id="$i18n.locale === 'pt_BR' ? 'languageSwitchPT' : 'languageSwitchEN'" @click="toggleLanguage" style="margin: 0;">
    </div>
    
    
            </div>
        </nav>
    </div>
    
    </template>
    <script>
    import { useRouter } from 'vue-router';
    import router from '../../router/index';
    
    export default {
        name: 'NavbarComponent',
        methods: {
            mudarRota() {
      // Use router.push para navegar para uma nova rota
      router.push('/about');
      router.push('/');
    },
    
            closeMenu() {
                const navbar = document.querySelector('.navbar-collapse');
                if (navbar.classList.contains('show')) {
                    const navbarToggler = document.querySelector('.navbar-toggler');
                    navbarToggler.click();
                }
            },
            toggleLanguage() {
        if (this.$i18n.locale === 'pt_BR') {
            this.$i18n.locale = 'en';
        } else {
            this.$i18n.locale = 'pt_BR';
        }
        
        
    },
    
            toggleMenu() {
                this.menuOpen = !this.menuOpen;
            },
            setLocale(locale) {
                this.$i18n.locale = locale;
            },
            refreshPage() {
                const router = useRouter();
                router.go(); // Reloads the current route
            }
        },
        data() {
            return {
                menuOpen: false
            };
        }
    };
    </script>
    
    
    <style scoped>
    
    .navbar-expand-lg .navbar-nav .nav-link:focus {
        outline: none; /* Remove a borda de foco */
        box-shadow: none; /* Remove qualquer sombra de foco */
        /* Adicione outros estilos desejados para o estado de foco, se necessário */
    }
    
    
    .principal{
        width: 100%;
        overflow-x: hidden;
    
    }
    .navbar{
        position: fixed;
        width: 100%;
        background-color: transparent;
        opacity: 0.9;
        overflow: hidden;
        justify-content: space-around;
    }
    
    .navbar a,.navbar {
        font-weight: bold;
        color: #000 !important;
        text-decoration: none;
        padding: 10px;
        font-size: 12px !important;
    }
    .menu{
     
        width: 60%;
        margin-left: auto;
        margin-right: auto;
    }
    .navbar-nav{
        display: flex !important;
        justify-content: space-between !important;
    }
    li:active   {
        border: red !important;
    }
    .navbar-toggler .hamburger-icon {
        display: block;
    }
    
    .navbar-toggler .close-icon {
        display: none;
    }
    
    .navbar-toggler.collapsed .hamburger-icon {
        display: block;
    }
    
    .navbar-toggler.collapsed .close-icon {
        display: none;
    }
    .navbar-nav {
        display: flex !important;
        justify-content: center !important;
        align-items: center;
        height: 100%;
    }
    
    .menu {
        width: auto;
        margin-left: auto;
        margin-right: auto;
        flex-grow: 1;
    }
    a {
    cursor: url('../../assets/img/cursor.svg'), auto;
    }
    .navbar-nav > li {
        margin-right: 20px;
    }
    
    
    
    @media screen and (max-width: 768px) {
    
    .logo-img{
    width: 50%;
    padding-left: 1px;
    }
    .logo-img img{
        width: 80%;
    }
    .form-switch{
    padding-right: 0;
    display: none;
    background-color: yellowgreen;
    }
    .navbar-toggler{
    
        margin-right: 20%;
    }
    }
    
    </style>
    