
<template>
  <div>
   <NavbarComponent/>
     <router-view/> 
     <footerComponent/>

  </div>


</template>

<script>
import NavbarComponent from './components/Navbar/NavbarComponent.vue'
import footerComponent from './components/Footer/footerComponent.vue'



export default {
  name: 'app',
  components:{
NavbarComponent,
footerComponent

  },

}
</script>


<style>
body{
   font-family: "Montserrat", sans-serif !important;
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
    

.form-check-input {
    transform: rotate(90deg);
    float: none !important;
}
.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
}
.form-check-input {
    border-color: #000;
    outline: 0;
    box-shadow: none;
}
.form-switch .form-check-input {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%0, 0, 0, 1%29'/%3e%3c/svg%3e");
    background-color: rgb(255, 255, 255) !important;
    border-color: #000000;
}
.form-check-label {
    padding-right: 38px;
    display: inline-block;
    color: #000000;
    font-weight: 700;
}

/* Animação dos numeros de experiencia 

.animated {
  animation: fadeInUp 1s ease-in-out;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.anos-experiencia-number{
  width: 80%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;


}

.anos-experiencia-number .experiencia{
  display: flex;
  justify-content: space-between;

align-items: center;
  text-align: start;
  
}
.anos-experiencia-number .experiencia span
{
  font-size: 12px;
}
.anos-experiencia-number .experiencia p{
  font-size: 60px;
  font-weight: bold;
  padding-right: 10px;
}
*/
</style>
