export const en={
    salutation:{
        hello: 'hello',
        sobre:'ABOUT',
        parceiros:'PARTNERS',
        home:'HOME',
        servicos:'SERVICES',
        blog:'BLOG',
        contacto:'CONTACT',
        entregas:'Reliable Deliveries: Punctuality and Quality Guaranteed',
        entregas_desc:'Our delivery services are fast, reliable and guarantee the arrival of your products safely and with quality. Count on us to deliver your items on time and in perfect condition',
        projectosmontagem:'Projects and Assembly: Transforming Ideas into Reality',
        projectos_montagem_desc:'From conception to assembly, we transform your vision into real spaces, with quality and excellence at each stage of the process.',
        bem_vindo:'Quality, Refinement, Sophistication, Everything your home needs.',
        bem_vindo_desc:'Solid partnerships with the best producers allow us to always offer tailor-made proposals to our customers, guaranteeing their satisfaction and our commitment.',
        sobre_home_title:'We are a team of excellence offering innovative and personalized solutions',
        sobre_home_desc:'Founded in 2017, Pavisani has a history marked by several victories and remarkable achievements.',
        empresa:'The company has progressed consistently, becoming a reference in the area. This trajectory was only possible thanks to the motivation of the team that constitutes it and the constant support of its customers and partners.',
        com_anos:'With years of industry experience, we combine technical knowledge with a keen eye for market trends, ensuring each project is executed with precision and style.',
        fazemos_title:'What we do',
        fazemos_desc:'At PAVISANI, we are proud to present an extensive list of successfully completed projects.',
        fazemos_pavimento_title:'FLOORS',
        fazemos_pavimento_desc:'Discover the transformative power of flooring - where every step is an expression of style and comfort.',
        fazemos_sanitario_title:'RESTROOM',
        fazemos_sanitario_desc:'Awaken to the luxury and incomparable functionality of our bathrooms, where every moment becomes an experience of comfort and sophistication.',
        fazemos_cozinha_title:'KITCHEN',
        fazemos_cozinha_desc:'Where every detail is designed to combine style and functionality, creating spaces that inspire and enchant.',
        trabalhos_title:'Our works',
        trabalhos_desc:'Suspendisse faucibus sed dolor eget posuere Sed id interdum urna. Sed dolor eget posuere Sed id interdum urna.',
        trabalhos_menu_cozinha:'Kitchen',
        trabalhos_menu_interior:'Interior',
        trabalhos_menu_banheiro:'Bathroom',
        trabalhos_menu_jardim:'Garden',
        galeria:'GALLEY'
    },
    
    trabalhos:{
        cozinhaDesc1:'The Epicenter of Domestic Life',
         cozinhaDesc2:' We transform kitchens into spaces that pulse with style and functionality, where every meal becomes a memorable experience.',
         desctrabalhos:'We transform spaces with quality and design, creating welcoming and personalized environments for each client.',
         Botaomostrarmais:'Show more',
        },
        
    about:{
        excelencia:'Excellence in Flooring and Sanitary',
        naPavi:'At PAVISANI, our mission is to raise the standard of quality and style in flooring and bathrooms. ',
        destaca:'Pavisani stands out in the Angolan market for offering a variety of high quality products for the construction, decoration and interior design sectors. We contribute to the sustainable development and well-being of the communities where we operate.',
        fornecemos:'We provide our customers with products and services that meet the highest standards of quality and designer excellence. With a vision focused on leadership and excellence, the company constantly seeks to innovate and adapt to market needs, ensuring the total satisfaction of its customers.',
        inovaca:'Innovation, Transparency and Commitment to quality. Through strategic partnerships with prestigious brands and a continuous commitment to excellence in all the projects we develop, Pavisani has become a reference in the sector, offering personalized solutions to meet the specific needs of each client.',
        historia:'Our story',
        fundada:'Founded in 2017, Pavini has a history marked by several victories and remarkable achievements.',
        tempo:'Over time, the company progressed consistently, becoming a reference in its field. This trajectory was only possible thanks to the motivation of our team and the constant support of our customers and partners.',
        expressamos:'We express our sincere gratitude to everyone who contributed to our success story.',
        apavisane:'Pavisani is a company that represents internationally renowned brands, such as Sanindusa, Pavigres, Tarket, JNF, Larus and Exporlux among others.',
  
    },
    parceiros:{
        fortalecendoTitle:'Strengthening Bonds, Building Success',
        acreditamos:"At PAVISANI, we believe that strong collaboration is essential for success. That's why we've established strategic partnerships with some of the industry's leading companies, working together to offer high-quality flooring and sanitary solutions and excellent service. ",
        nossosParceiros:'Our partners are selected based on strict criteria of quality, reliability and commitment to customer satisfaction. We work side by side with architects, interior designers, construction companies and suppliers to ensure each project is executed flawlessly, from concept to completion.',
        valorizamos:'We deeply value these partnerships and recognize the unique contribution each brings to our work. Together, we strengthen ties, share knowledge and drive innovation, constantly raising the standard of what is possible to achieve.',
    }
    ,
    servico:{
        servicoTitle:'Your Complete Flooring and Sanitary Solution',
        servicoPavi:"At PAVISANI, we offer a variety of specialized services to meet each client's unique needs. From personalized consultation to meticulous installation, we are committed to providing solutions that transform your spaces with exceptional elegance and functionality."
    },
    contacto:{
        contactoTitle:'Get in touch with us',
        duvida:'If you have any questions, queries or would like to know more about our services, please do not hesitate to contact us. Our friendly and knowledgeable team is ready to meet your needs and provide personalized assistance.'
    },

    formulario:{
        nome:'Full name',
        email:'Your email',
        assunto:'Subject',
        numero:'Number',
        mensagem:'Message'
    },
    subtitles:{
        sobre:'About',
        parceiros:'Partners',
        servicos:'services',
        blog:'Blog',
        contacto:'Contact',
        galeria:'GALLEY',
        campos:'Mandatory fields',
        enviar:'To send',
        loja1:'STORE 1',
        loja2:'STORE 2',
    },
    galeria:{
        copyGaleria:'Explore our Showroom at PAVISANI, where inspiration takes shape. Discover a variety of thoughtfully designed environments, showcasing our collection of flooring, bathrooms, interior decor and more. Visit us and let yourself be enchanted by the quality and beauty that will transform your spaces.',
        
    }

}