<template>
    <!-- Conteúdo Principal -->
    <div class="content-fhater" :style="{ backgroundImage: `url(${currentImage})` }">
      <!-- Seção de Texto -->
      <div class="content-all">
        <div class="content-fluid content-text col-md-7">
          <div class="container-fluid">
            <div class="element" style="display: flex;">
              <div class="row" >
                <div class="col-8 container-text">
                  <div class="bounce" v-if="$i18n.locale === 'pt_BR'">
                    <transition name="fade">
                      <h1 class="animate-text" v-html="currentText" ></h1>
                    </transition>
                  </div>
                  <div class="bounce" v-else >
                    <transition name="fade">
                      <h1 class="animate-text" v-html="currentText" ></h1>
                    </transition>
                  </div>
                <!--  <hr>
                  <div class="copy-text">
                    <transition name="fade">
                      <p v-html="currentText1"></p>
                    </transition>
                  </div>
                  -->
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <!-- Seção de Slide -->
        <div class="content-slide content-fluid col-md-4">
          <div class="content-slide-card">
             
            <div class="card">
              <a href="/galeria">
                <div class="card-bod prev">
                <span>CONHEÇA O NOSSO SHOWROOM</span>
                <p></p>
              </div>
              </a>
           
            </div>
            <div class="card">
              <a href="/parceiros">
                <div class="card-bod prev"  >  <!--@click="alterarTexto1EprevSlide(1)"-->
                <span>CONHEÇA	AS	NOSSAS	MARCAS</span>
                
                <p></p>
              </div>
              </a>
          
            </div>
           
           
          </div>
        </div>
      </div>
    </div>

</template>

<script>
import ScrollReveal from 'scrollreveal';

export default {
  data() {
    return {
     
      
      
      texts: [
        {
          text: this.$t('QUALIDADE, REQUINTE, SOFISTICAÇÃO, TUDO O QUE A SUA CASA PRECISA.'),
         // text1: 'Parcerias sólidas com os melhores produtores, permitem-nos oferecer sempre propostas à medida dos nossos clientes, garantindo a sua satisfação e o nosso comprometimento.',
        },
        {
          text: this.$t('TRANSFORMAMOS	DESEJOS	EM	REALIDADE,	SEMPRE	A	PENSAR	NO	SEU	CONFORTO	E	BEM-ESTAR.'),
        //  text1: '',
        },
        {
          text: this.$t('ESPAÇOS	COM	PERSONALIDADE,	QUALIDADE	E	EXCELÊNCIA.'),
         
        },
      ],
      images: [
        require('@/assets/img/fundo.webp'),
        require('@/assets/img/loja1.webp'),
        require('@/assets/img/loja2.webp'),
      ],
      currentIndex: 0,
      intervalId: null,
      backgroundTransition: 'background-image 2s ease', // Tempo de transição do fundo
      textTransition: 'opacity 0.5s ease', // Tempo de transição do texto
    };
  },
  computed: {
    
    currentImage() {
      return this.images[this.currentIndex];
    },
    currentText() {
      return this.texts[this.currentIndex].text;
    },
    currentText1() {
      return this.texts[this.currentIndex].text1;
    },
  },
  mounted() {
    this.startSlide();
    this.initScrollReveal();
  },
  methods: {

    initScrollReveal() {
      this.sr = ScrollReveal();

      this.sr.reveal('.container', {
        duration: 2000,
        distance: '90px',
        origin: 'left',
        viewFactor: 0.5,
      });
    },
    alterarTexto1EprevSlide(index) {
      this.currentIndex = index;
      this.backgroundTransition = 'background-image 2s ease';
      this.textTransition = 'opacity 0.5s ease';
    },
    alterarTextonextSlide(index) {
      this.currentIndex = index;
      this.backgroundTransition = 'background-image 2s ease';
      this.textTransition = 'opacity 0.5s ease';
    },
    nextSlide() {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.backgroundTransition = 'background-image 2s ease';
      this.textTransition = 'opacity 0.5s ease';
    },
    startSlide() {
      this.intervalId = setInterval(() => {
        this.nextSlide();
      }, 10000);
    },
    setLocale(locale) {
      this.$i18n.locale = locale;

      console.log(this.$i18n.locale);
      console.log("Aqui");

    }, 
 
  },
  beforeUnmount() {
    clearInterval(this.intervalId);

    if (this.sr) {
      this.sr.destroy();
    }
  },
};
</script>

<style scoped>
/* Seus estilos aqui */

.content-fhater {
  justify-content: center;
  align-content: center;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  transition: background-image 2s ease; /* Transição suave para o fundo */
}

.content-all {
  display: flex;
  height: 100vh;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding: 0 120px;
  overflow: hidden;
  width: 100%; 
 
}

.content-text h1,
.copy-text {
  color: white;
  text-align: start;
  margin-bottom: 6%;
  transition: opacity 0.5s ease; /* Transição suave para o texto */
}

.content-text h1 {
  font-size: 3em;
  font-weight: 700;
  line-height: 1.2;
}

.copy-text {
  font-size: 20px;
  padding: 1%;
}

.content-text hr {
  background-color: white;
  border: solid 0.5px white;
  width: 58%;
  transform: rotate(169deg);
  margin-top: 2%;
  margin-bottom: 13%;
  opacity: 1;
}

.container-text {
  width: 90%;
  margin-top: 20%;
}

.content-slide {
  width: 68%;
  display: flex;
  margin-top: 35%;
}

.content-slide .slides-control {
  position: relative;
  width: 100%;
  transform: rotate(-90deg);
}

.card-bod {
  padding-left: 10px;
  padding-top: 10px;
}

.content-slide-card {
  width: 45%;
  display: flex;
}

.content-slide-card .card {
  width: 50%;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  border: solid 4px #CCCCCC;
  color: white;
  margin-left: 10px;
  -webkit-box-shadow: 10px 10px 62px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 62px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 62px 0px rgba(0, 0, 0, 0.75);
  background-color: rgba(0, 0, 0, 0.492);
}

.content-slide-card .card:hover {
  transform: rotate(360deg);
}

.card  span {
  font-weight: bolder;
  font-size: 16px;
}
a{
  text-decoration: none;
  color: white;
}
a:hover{
  color: whitesmoke;
}

.card  span,
p {
  text-align: start;
  display: flex;
  text-align: center;

}

.card p {
  margin-top: 5px;
  font-size: 11px;
}

button {
  background-color: #464545;
  border: none;
  width: 40px;
  height: 44px;
}

button:hover {
  background-color: #0b897f;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.10s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.animate-text {
  animation: text-shadow 1.5s ease-in-out;
}
/*
@keyframes text-shadow {
  0% {
    transform: translateY(0);
    text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
  }

  20% {
    transform: translateY(-1em);
    text-shadow: 0 0.125em 0 #0c2ffb, 0 0.25em 0 #2cfcfd, 0 -0.125em 0 #fb203b, 0 -0.25em 0 #fefc4b;
  }

  40% {
    transform: translateY(0.5em);
    text-shadow: 0 -0.0625em 0 #0c2ffb, 0 -0.125em 0 #2cfcfd, 0 0.0625em 0 #fb203b, 0 0.125em 0 #fefc4b;
  }

  60% {
    transform: translateY(-0.25em);
    text-shadow: 0 0.03125em 0 #0c2ffb, 0 0.0625em 0 #2cfcfd, 0 -0.03125em 0 #fb203b, 0 -0.0625em 0 #fefc4b;
  }

  80% {
    transform: translateY(0);
    text-shadow: 0 0 0 #0c2ffb, 0 0 0 #2cfcfd, 0 0 0 #fb203b, 0 0 0 #fefc4b;
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}

/* Celulares */
@media screen and (max-width: 768px) {
 
  .content-all {
    height: 100vh;
  }
  .content-slide{
  width: 100%;
  margin-top: 5%;
  padding: 10px;
 }

  .content-fhater{
    width: 100%;
    height: 100vh;
  }
    .container{
      width: 100%;
    }
  .content-all{
    display: block;
    padding: 0;
  }
  .container-text{
    width: 100%;
    margin-top: 50%;

  }

    .container-text h1 {
       font-size: 30px;
       padding: 10px;
    }
    .copy-text{
      width: 100%;
      font-size: 18px;
    }
    .card-bod{
      width: 100%;
      height: 80px;
    }
    .card-bod p{
      display: none;
    }
    .content-slide-card{
      width: 100%;
      justify-content: space-around;
 
    }
    .content-text{
        width: 100%; /* Defina a largura como 100% para ocupar toda a largura na versão mobile */
        display: flex;   
    }
    .card{
      align-items: center;
    }
    .content-slide-card .card{
      width: 40%;
      display: flex;
      text-align: center;
    }
    .content-text hr{
      margin-top: 1%;
    }

    /* Tabletes */

    /* Estilos para dispositivos de tela pequena */

}
@media screen and (max-width:820px){

.content-all{
  margin-top: 20%;
    display: flex;
    flex-wrap: wrap;
  }
  .container-fluid{
    width: 100%;
  }
  .content-text{
    width: 100%;
  }
  .container-text{
    width: 100%;
  }
  .container-text h1 {
       font-size: 40px;
       padding: 10px;
    }
}

@media screen and (min-width:767px) and (max-width: 1231px){
   .content-all{
    display: flex;
  }

  .container-text{
    width: 100%;
    margin-top: 20%;
  }
  .container-text h1 {
       font-size: 30px;
       padding: 10px;
    }
    .content-slide-card{
      width: 70%;

    }
  .content-slide-card .card{
      width: 60%;
      display: flex;
      text-align: center;
    }

    
}


/*Iped Ir 4 */

@media screen and (min-width:820px) and (max-width: 1180px){
  .content-all{
    height: 90vh;
  }
}
/* Iphone pequeno */

@media screen and (min-width:320px) and (max-width: 556px){
  .container-text h1 {
       font-size: 25px;
       padding: 10px;
       margin-top: 3%;

    }
  
}

/* cards */
@media screen and (min-width:769px) and (max-width: 820px){ 
  .content-slide-card .card span{
    font-size: 14px;

  }
  .content-slide-card .card {
  width: 70% !important;
}

}

/* Tablet Smart */
@media screen and (min-width:434px) and (max-width: 821px){ 

.content-all{
  margin-bottom: 10%
}
.content-slide-card{
  margin-top: -10%;
}

.content-slide-card .card {
  width: 40%;
  font-size: 16px;
}
h1{
  font-size: 2rem !important;
}
}

</style>
