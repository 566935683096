<template>
  <div class="home grid-wrap">
    <div v-for="(image, index) in imagesToDisplay" :key="index" class="trabalho-image" @click="alternarMaximizacao(index)">
      <img :src="image.src" :alt="image.alt" />
    </div>

    <div v-if="isMaximizada" class="imagem-maximizada">
      <img :src="imagesToDisplay[imagemMaximizadaIndex].src" :alt="imagesToDisplay[imagemMaximizadaIndex].alt" />
      <button class="btn-navegacao btn-anterior" @click="navegarAnterior">&lt;</button>
      <button class="btn-navegacao btn-proximo" @click="navegarProximo">&gt;</button>
      <button class="btn-fechar" @click="alternarMaximizacao(null)">X</button>
    </div>
  </div>

  <button @click="showNextBatch" v-if="showButton" class="btn-mostrar-mais">{{ $t('trabalhos.Botaomostrarmais') }}</button>
</template>

<script>
export default {
  data() {
    return {
      isMaximizada: false,
      imagemMaximizadaIndex: null,
      images: [
        { src: '/img/pia.png', alt: 'Image 1' },
        { src: '/img/interior.webp', alt: 'Image 2' },
        { src: '/img/jardin.png', alt: 'Image 3' },
        { src: '/img/sanita.png', alt: 'Image 4' },
        { src: '/img/banheira.png', alt: 'Image 5' },
        { src: '/img/luzes.png', alt: 'Image 6' },
        { src: '/img/luzes1.png', alt: 'Image 7' },
        { src: '/img/pia.png', alt: 'Image 8' },
        { src: '/img/sanita.png', alt: 'Image 9' },
        { src: '/img/banheira.png', alt: 'Image 10' },
        { src: '/img/banheira.png', alt: 'Image 11' },
        { src: '/img/banheira.png', alt: 'Image 12' },
      ],
      batchSize: 6,
      currentIndex: 0,
    };
  },
  computed: {
    imagesToDisplay() {
      return this.images.slice(0, this.currentIndex + this.batchSize);
    },
    showButton() {
      return this.currentIndex + this.batchSize < this.images.length;
    },
  },
  methods: {
    showNextBatch() {
      this.currentIndex += this.batchSize;
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
    }, 
    alternarMaximizacao(index) {
      this.isMaximizada = index !== null;
      this.imagemMaximizadaIndex = index;
    },
    navegarAnterior() {
      if (this.imagemMaximizadaIndex > 0) {
        this.imagemMaximizadaIndex--;
      } else {
        this.imagemMaximizadaIndex = this.imagesToDisplay.length - 1;
      }
    },
    navegarProximo() {
      if (this.imagemMaximizadaIndex < this.imagesToDisplay.length - 1) {
        this.imagemMaximizadaIndex++;
      } else {
        this.imagemMaximizadaIndex = 0;
      }
    },
  },
};
</script>

<style scoped>
.home {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  margin-top: 5%;
  margin-bottom: 1%;
}

.trabalho-image {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  margin-right: 10px;
  margin-left: 10px;
  position: relative;
}

.trabalho-image img {
  width: 100%;
  transition: transform 0.3s ease;
}

.trabalho-image:hover img {
  transform: scale(1.05);
}

.imagem-maximizada {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagem-maximizada img {
    max-width: auto; /* Define a largura máxima como automática */
    max-height: auto; /* Define a altura máxima como automática */
  }
.btn-navegacao {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 32px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-navegacao:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.btn-anterior {
  left: 10px;
}

.btn-proximo {
  right: 10px;
}

.btn-fechar {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border: none;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-fechar:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.btn-mostrar-mais {
  margin-top: 20px;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #0B897F;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-mostrar-mais:hover {
  background-color: #0B6F62;
}
@media screen and (max-width: 768px) {
.home{
  width: 90%;
  flex-wrap: wrap;
}
.trabalho-image{
width: 100%;
}
}




</style>
