export const pt_BR={
    salutation:{
        home:'HOME',
        hello: 'Ola Pessoa',
        sobre:'SOBRE',
        parceiros:'PARCEIROS',
        servicos:'SERVIÇOS',
        blog:'BLOG',
        contacto:'CONTACTO',
        entregas:'Entregas Confiáveis: Pontualidade e Qualidade Garantidas',
        entregas_desc:'Nossos serviços de entrega são rápidos, confiáveis e garantem a chegada dos seus produtos com segurança e qualidade. Conte conosco para entregar seus itens no prazo e em perfeitas condições',
        projectosmontagem:'Projetos e Montagens: Transformando Ideias em Realidade',
        projectos_montagem_desc:'Da concepção à montagem, transformamos sua visão em espaços reais, com qualidade e excelência em cada etapa do processo.',
        bem_vindo:'QUALIDADE, REQUINTE, SOFISTICAÇÃO, TUDO O QUE A SUA CASA PRECISA.',
        bem_vindo_desc:'Parcerias sólidas com os melhores produtores, permitem-nos oferecer sempre propostas à medida dos nossos clientes, garantindo a sua satisfação e o nosso comprometimento.',
        sobre_home_title:'Somos uma equipe de excelência oferecer soluções inovadoras e personalizadas',
        sobre_home_desc:'Fundada em 2017, a Pavisani apresenta uma história marcada por diversas vitórias e feitos marcantes. ',
        empresa:'A empresa progrediu de forma consistente, tornando-se uma referência na área. Essa trajetória só foi possível graças à motivação da equipa que a constitui e ao apoio constante dos seus clientes e parceiros.',
        com_anos:'Com anos de experiência no setor, combinamos conhecimento técnico com um olhar atento para as tendências do mercado, garantindo que cada projeto seja executado com precisão e estilo.',
        fazemos_title:'O que fazemos',
        fazemos_desc:'Na PAVISANI, temos o orgulho de apresentar uma extensa lista de projetos concluídos com sucesso.',
        fazemos_pavimento_title:'PAVIMENTOS',
        fazemos_pavimento_desc:'Descubra o poder transformador dos pavimentos - onde cada passo é uma expressão de estilo e conforto.',
        fazemos_sanitario_title:'SANITÁRIO',
        fazemos_sanitario_desc:'Desperte para o luxo e a funcionalidade incomparável dos nossos sanitários, onde cada momento se torna uma experiência de conforto e sofisticação.',
        fazemos_cozinha_title:'COZINHA',
        fazemos_cozinha_desc:'Onde cada detalhe é pensado para unir estilo e funcionalidade, criando espaços que inspiram e encantam.',
        trabalhos_title:'Nossos trabalhos',
        trabalhos_desc:'Suspendisse faucibus sed dolor eget posuere Sed id interdum urna. Sed dolor eget posuere Sed id interdum urna.',
        trabalhos_menu_cozinha:'Cozinha',
        trabalhos_menu_interior:'Interior',
        trabalhos_menu_banheiro:'Banheiro',
        trabalhos_menu_jardim:'Jardim',
        galeria:'GALERIA',
      

    },
    trabalhos:{
       cozinhaDesc1:' O Epicentro da Vida Doméstica',
        cozinhaDesc2:' Transformamos cozinhas em espaços que pulsam com estilo e funcionalidade, onde cada refeição se torna uma experiência memorável.',
        Botaomostrarmais:'Mostrar mais',
        desctrabalhos:'Transformamos espaços com qualidade e design, criando ambientes acolhedores e personalizados para cada cliente.'
    },
    
    about:{
        excelencia:'Excelência em Pavimentos e Sanitários',
        naPavi:'Na PAVISANI, nossa missão é elevar o padrão de qualidade e estilo em pavimentos e sanitários. ',
        destaca:'A Pavisani destaca-se no mercado Angolano por oferecer uma variedadede de produtos de alta qualidade para os sectores de contrução, decoração e designer de interiores Contribuimos para o desenvolvimento sustentavel e o bem-estar das comunidades onde actuamos.',
        fornecemos:'Fornecemos aos nossos clientes produtos e serviços que respondem aos mais altos padrões de qualidade e designer de excel^nncia. Com uma visão voltada para liderança e excelência, a empresa busca constantemente inovar adaptar-se às necessidades do mercado, garantindo a satisfação total de seus clientes.',
        inovaca:'Inovação, Transparência e Compromisso com a qualidade. Através de parcerias estratégicas com marcas de prestigio e um comprometimento continuo com a excelência em todos os projetos que desenvolvemos, a Pavisani tornou-se uma referência nno sector, oferecendo soluções personalizadas para atender às necessidades especificas de cada cliente.',
        historia:'Nossa História',
        fundada:'Fundada em 2017, a Pavini possui uma história marcada por diversas   vitórias e feitos marcantes.',
        tempo:'Com o passar do tempo, a empresa progrediu de forma consistente,  tornando-se uma referência na sua área.Essa trajetória só foi  possíveo graças á motivação da nossa equipa e ao apoio constante  dos nossos cliente e parceiros.',
        expressamos:'Expressamos a nossa gratidão sincera a tosos os que contribuiram  para a nossa história de sucesso.', 
        apavisane:'Pavisani é uma empresa que representa marcas de renome internacional, como Sanindusa, Pavigres, Tarket, JNF, Larus e Exporlux entre outras.',
    },
    parceiros:{
        fortalecendoTitle:'Fortalecendo Laços, Construindo Sucesso',
        acreditamos:'Na PAVISANI, acreditamos que uma colaboração sólida é essencial para o sucesso. É por isso que estabelecemos parcerias estratégicas com algumas das principais empresas do setor, trabalhando juntas para oferecer soluções de pavimentos e sanitários de alta qualidade e excelência em serviços. ',
        nossosParceiros:'Nossos parceiros são selecionados com base em critérios rigorosos de qualidade, confiabilidade e compromisso com a satisfação do cliente. Trabalhamos lado a lado com arquitetos, designers de interiores, construtoras e fornecedores para garantir que cada projeto seja executado com perfeição, desde o conceito até a conclusão.',
        valorizamos:'Valorizamos profundamente essas parcerias e reconhecemos a contribuição única que cada uma traz para o nosso trabalho. Juntos, fortalecemos laços, compartilhamos conhecimento e impulsionamos a inovação, elevando constantemente o padrão do que é possível alcançar.',
    },
    servico:{
        servicoTitle:'Sua Solução Completa em Pavimentos e Sanitários',
        servicoPavi:'Na PAVISANI, oferecemos uma variedade de serviços especializados para atender às necessidades únicas de cada cliente. Desde consultoria personalizada até instalação meticulosa, estamos comprometidos em fornecer soluções que transformem seus espaços com elegância e funcionalidade excepcionais.'
    },
    contacto:{
        contactoTitle:'Entre em Contacto Connosco',
        duvida:'Se você tiver alguma dúvida, consulta ou quiser saber mais sobre nossos sserviços, não hesite em entrar em contato conosco. Nossa equipe amigável e experiente está pronta para atender suas necessidades e fornecer assistência personalizada.'
    },
    formulario:{
        nome:'Nome Completo',
        email:'Seu Email',
        assunto:'Assunto',
        numero:'Telefone',
        mensagem:'Mensagem'
    },
    subtitles:{
        sobre:'Sobre',
        parceiros:'Parceiros',
        servicos:'Serviços',
        blog:'Blog',
        contacto:'Contacto',
        galeria:'GALERIA',
        campos:'Campos obrigatório',
        enviar:'Enviar',
        loja1:'LOJA 1',
        loja2:'LOJA 2',

    },
    galeria:{
        copyGaleria:'Explore o nosso Showroom na PAVISANI, onde a inspiração ganha forma. Descubra uma variedade de ambientes cuidadosamente projetados, exibindo nossa coleção de pavimentos, sanitários, decoração de interiores e muito mais. Visite-nos e deixe-se encantar pela qualidade e beleza que transformarão seus espaços.',
        
    }
    

}