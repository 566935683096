<template>
  <div class="trabalhos container">
    <div class="trabalhos-title">
      <div class="trabalho-logo">
        <h2>{{ $t('salutation.trabalhos_title') }}</h2>
        <div class="icon-logo">
          <img src="/img/fazemos.svg" alt="">
        </div>
      </div>
      <div class="trabalhos-select">
        <ul>
          <li :class="{ active: categoria === 'cozinha' }" @click="alterarCategoria('cozinha')">{{ $t('salutation.trabalhos_menu_cozinha') }}</li>
          <li :class="{ active: categoria === 'interior' }" @click="alterarCategoria('interior')">{{ $t('salutation.trabalhos_menu_interior') }}</li>
          <li :class="{ active: categoria === 'banheiro' }" @click="alterarCategoria('banheiro')">{{ $t('salutation.trabalhos_menu_banheiro') }}</li>
          <li :class="{ active: categoria === 'jardin' }" @click="alterarCategoria('jardin')">{{ $t('salutation.trabalhos_menu_jardim') }}</li>
        </ul>
      </div>
    </div>
    <div class="desc-trabalhos">
      <p>
        {{ $t('trabalhos.desctrabalhos') }}
      </p>
    </div>
    <div class="cozinha container">
      <div class="cozinha-image" :class="{ 'animarImagem': isTextAnimating }">
        <img :src="UrlImageprincipal" alt="">
      </div>
      <div class="desc-cozinha">
        <span>{{ categoria }}</span>
        <h3>{{ tituloCategoria }}</h3>
        <span>{{ textoCategoria }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { categoriasPt, categoriasEn } from './traducoes.js';

export default {
  data() {
    return {
      UrlImageprincipal: '/img/cozinha.png',
      categoria: 'cozinha', // Começa com 'cozinha' como ativo
      categorias: {}
    };
  },
  created() {
    // Verifica o idioma atual e define os textos correspondentes
    if (this.$i18n.locale === 'pt_BR') {
      this.categorias = categoriasPt;
    } else {
      this.categorias = categoriasEn;
    }
  },
  computed: {
    descricaoCategoria() {
      return `Suspendisse faucibus sed dolor eget posuere Sed id interdum urna. Sed dolor eget posuere Sed id interdum urna. `;
    },
    tituloCategoria() {
      return this.categorias[this.categoria].titulo;
    },
    textoCategoria() {
      return this.categorias[this.categoria].texto;
    }
  },
  methods: {
    setLocale(locale) {
      this.$i18n.locale = locale;
      
      console.log(this.$i18n.locale);
      console.log("Aqui");

    },
    alterarCategoria(categoria) {
      this.categoria = categoria;
      this.alterarImagemPrincipal(categoria);
    },
    alterarImagemPrincipal(categoria) {
      if (categoria === 'cozinha') {
        this.UrlImageprincipal = '/img/cozinha.png';
      } else if (categoria === 'interior') {
        this.UrlImageprincipal = '/img/interior.webp';
      } else if (categoria === 'banheiro') {
        this.UrlImageprincipal = '/img/banheiro.png';
      } else if (categoria === 'jardin') {
        this.UrlImageprincipal = '/img/jardin.png';
      }
    }
  }
};
</script>
  <style scoped>

.icon-logo{
  width: 3%;
  padding-left: 1%;
}
  /* Nossos Trabalhos */
  .trabalhos {
    margin-top: 10%;
    width: 80%;
    flex-wrap: wrap;
  }
  .trabalhos ul {
    cursor: pointer;
    list-style: none;
  }
  .trabalhos ul li.active{
    font-weight: bolder;
  }
  /*
  .trabalhos ul li.active::after{
    content: url('../../../public/img/indicador.svg');;
    font-size: 20px;
  }*/
  .trabalhos-title {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
  }
  .trabalhos-title h2 {
    font-size: 40px;
  }
  .trabalho-logo {
    display: flex;
    width: 80%;
  }

  .desc-trabalhos {
    width: 40%;
    font-size: 14px;
    text-align: start;
    color: #000000;
    margin-left: 5%;
  }
  /* Cozinha */
  .cozinha {
    display: flex;
    border: 1px solid #e1e1e1;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    height: 60vh;
    padding: 100px;
    margin-top: 10%;
  }
  .cozinha h3 {
    font-weight: 900;
    font-size: 30px;
  }
  .desc-cozinha {
    padding: 40px;
    width: 100%;
  }
  .desc-cozinha p,
  .desc-cozinha span {
    color: #898989;
    font-size: 14px;
  }

  .cozinha-image{
    position: relative;
  /*  animation: text-shadow 1.5s ease-in-out; */
  }

 

  .cozinha-image img {
    height: 70vh;
    margin-top: -14%;
    margin-left: -10%;
  }

  /* Animação 

  @keyframes text-shadow {
    0% {  
        transform: translateY(0);
        text-shadow: 
            0 0 0 #0c2ffb, 
            0 0 0 #2cfcfd, 
            0 0 0 #fb203b, 
            0 0 0 #fefc4b;
    }

    60% {  
        transform: translateY(-1em);
        text-shadow: 
            0 0.125em 0 #0c2ffb, 
            0 0.25em 0 #2cfcfd, 
            0 -0.125em 0 #fb203b, 
            0 -0.25em 0 #fefc4b;
    }

    60% {  
        transform: translateY(0.5em);
        text-shadow: 
            0 -0.0625em 0 #0c2ffb, 
            0 -0.125em 0 #2cfcfd, 
            0 0.0625em 0 #fb203b, 
            0 0.125em 0 #fefc4b;
    }
    
   80% {
        transform: translateY(-0.25em);
        text-shadow: 
            0 0.03125em 0 #0c2ffb, 
            0 0.0625em 0 #2cfcfd, 
            0 -0.03125em 0 #fb203b, 
            0 -0.0625em 0 #fefc4b;
    }

    80% {  
        transform: translateY(0);
        text-shadow: 
            0 0 0 #0c2ffb, 
            0 0 0 #2cfcfd, 
            0 0 0 #fb203b, 
            0 0 0 #fefc4b;
    }
}

@media (prefers-reduced-motion: reduce) {
    * {
      animation: none !important;
      transition: none !important;
    }
}
*/

@media screen and (max-width: 768px) {

.trabalho-logo h2{
  font-size: 25px;
  width: 100%;
  font-weight: bold;
  color: #000000;
}
.trabalhos-title{
  width: 100%;
}


.trabalhos-select{
  width: 90%;
  margin-top: 2%;
  display: flex;
}
.trabalhos-select ul{
  display: flex;
  margin-top: 2%;
  justify-content: space-between;
  width: 100%;
}
.cozinha{
  padding:0;
  flex-wrap: wrap;
  margin-bottom: 10%;
  height: 105vh;
}
.desc-trabalhos{
  width: 90%;
  margin-bottom: 10%;
}

.cozinha-image{
  width: 100%;
}
.cozinha-image img{
  width: 100%;
}
.cozinha{
  width: 90%;
}
.desc-cozinha {
  width: 100%;
}
.desc-cozinha h3{
  font-size: 16px;
  
}
.cozinha-image img {
    height: 50vh;
    margin-top:0;
    margin-left:0;
  }


  
}


@media only screen and (max-width: 320px) {
  .cozinha  {
  height: 150vh;
  
}

}

@media screen and (max-width: 1024px) and (max-height: 600px) {
.cozinha-image{
  width: 100%;
}
    .cozinha-image img {
   width: 100%;
   height: 55vh;
}
}

@media screen and (max-width: 1280px) and (max-height: 800px) {

  .cozinha-image{
  width: 100%;
}
    .cozinha-image img {
   width: 100%;
}
.cozinha{
  display: flex !important;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 107vh;
  width: 100%;

}
}
@media screen and (min-width:769px) and (max-width: 1170px){ 

.cozinha{
  height:130vh !important;
}
}

/*Iped Ir 4 */

@media screen and (min-width:820px) and (max-width: 1180px){
.trabalhos-select{
 width: 100%;
 display: flex;
}
.trabalhos-select ul{
  display: flex;
  padding: 20px;
}
.trabalhos-select ul li{
  padding-left: 20px;
}
.desc-trabalhos{
  width: 100%;
}
.cozinha-image{
  width: 100%;
}
.cozinha-image img{
  padding: 10px;
  width: 100%;
}
.cozinha{
  flex-wrap: wrap;
  height: 105vh;
}
}


/*  Galax Tab S7 */
@media screen and (min-width:800px) and (max-width: 1280px){

.cozinha-image{
  width: 100%;

}
.cozinha-image img{
  width: 100%;
}
.cozinha{
  flex-wrap: wrap;
  height: 105vh;
  width: 100%;
}
.container{
  width: 100%;
}
.trabalhos-select ul{
  display: flex;
  padding: 20px;
}
.trabalhos-select ul li{
  padding-left: 20px;
}
.desc-trabalhos{
  width: 100%;
}
}

/* Portatil Sansung */
@media screen and (max-width: 1024px) and (max-height: 600px) {

.cozinha{
width: 100%;
flex-wrap: nowrap;
height: 105vh;

}

}


@media screen and (max-width: 1280px) and (max-height: 800px) {
  .projeto{
  height: 70vh;
}
}

@media screen and (min-width:320px) and (max-width: 556px){
  .cozinha{
  flex-wrap: wrap;
  height: 105vh;
  width: 100%;
}
}

/* Suifth */
@media screen and (width:1368px){
  .cozinha{
width: 100%;
  flex-wrap: wrap;
  height: 105vh;

}
.cozinha-image{
  width: 40%;

}

}

  </style>
  