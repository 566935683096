// traducoes.js

export const categoriasPt = {
    cozinha: {
      titulo: 'O Epicentro da Vida Doméstica',
      texto: 'Transformamos cozinhas em espaços que pulsam com estilo e funcionalidade, onde cada refeição se torna uma experiência memorável.'
    },
    interior: {
      titulo: 'Design, Estilo e Funcionalidade',
      texto: 'Descubra o poder da transformação com os nossos serviços de design de interiores. Dos pavimentos aos sanitários, cada detalhe é cuidadosamente planejado para criar espaços que encantam e inspiram.'
    },
    banheiro: {
      titulo: 'Elegância em Cada Detalhe',
      texto: 'Explore uma nova dimensão de conforto e estilo com os nossos serviços para banheiros. Desde a escolha dos pavimentos até os acessórios de sanitários, criamos ambientes que combinam beleza e praticidade, transformando o banheiro em um refúgio de luxo e bem-estar.'
    },
    jardin: {
      titulo: 'Oásis de Serenidade em Sua Casa',
      texto: 'Descubra o potencial do seu espaço externo com nossos serviços de design de jardins. Da seleção de pavimentos ao paisagismo, transformamos seu jardim em um refúgio de beleza natural e tranquilidade.'
    }
  };
  
  export const categoriasEn = {
    cozinha: {
      titulo: 'The Epicenter of Domestic Life',
      texto: 'We transform kitchens into spaces that pulse with style and functionality, where every meal becomes a memorable experience.'
    },
    interior: {
      titulo: 'Design, Style, and Functionality',
      texto: 'Discover the power of transformation with our interior design services. From floors to toilets, every detail is carefully planned to create spaces that delight and inspire.'
    },
    banheiro: {
      titulo: 'Elegance in Every Detail',
      texto: 'Explore a new dimension of comfort and style with our bathroom services. From choosing floors to toilet accessories, we create environments that combine beauty and practicality, transforming the bathroom into a luxury retreat of well-being.'
    },
    jardin: {
      titulo: 'Oasis of Serenity in Your Home',
      texto: 'Discover the potential of your outdoor space with our garden design services. From floor selection to landscaping, we turn your garden into a haven of natural beauty and tranquility.',

    },

  };
  